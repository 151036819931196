import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
export default {
  name: "NavbarComponent",
  inheritAttrs: false,
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["auth/user"]);
    const profile = computed(() => store.getters["companies/getCompany"]);
    const openActions = () => {
      let t = document.getElementById("offcanvas-toggle");
      t.click();
    };
    return {
      user,
      profile,
      openActions,
      differenceInCalendarDays
    };
  }
};