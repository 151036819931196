import some from "lodash/some";
import store from "../store";
export default ((el, binding, vnode) => {
  const checkRole = (el, binding, vnode, user) => {
    let roles = binding.value;
    const requiredRoles = Object.keys(binding.modifiers);
    if (typeof roles === 'string') roles = roles.split('|');else if (!roles) roles = [];
    let hasRole = false;
    if (requiredRoles.length > 0) {
      hasRole = some(requiredRoles, role => user.roles?.includes(role));
      if (roles.length > 0) hasRole = hasRole && some(roles, role => user.roles?.includes(role));
    } else hasRole = some(roles, role => user.roles?.includes(role));
    if (!hasRole) {
      el?.parentNode?.removeChild(el);
      el?.setAttribute('data-removed', true);
    } else {
      if (el.getAttribute('data-removed')) {
        el?.parentNode?.appendChild(el);
        el?.removeAttribute('data-removed');
      }
    }
  };
  let user = store.getters['auth/user'];
  if (user) {
    checkRole(el, binding, vnode, user);
  } else {
    const unwatch = store.watch((state, getters) => getters['auth/user'], user => {
      if (user) {
        checkRole(el, binding, vnode, user);
        unwatch();
      }
    }, {
      immediate: true
    });
  }
});