export default {
  props: {
    to: String,
    disabled: Boolean
  },
  setup() {
    const closeDrawer = () => {
      let elem = document.getElementById("my-drawer-2");
      if (elem) elem.click();
    };
    return {
      closeDrawer
    };
  }
};