import store from "../store";
export default ((el, binding, vnode) => {
  const checkPerm = (el, binding, vnode, user) => {
    let perms = binding.value;
    if (typeof perms === 'string') perms = perms.split('|');else if (!perms) perms = [];
    let hasPerm = false;
    // permissions prefixed with @ are required and permissions prefixed with ! are prohibited
    hasPerm = perms.some(perm => {
      if (perm.startsWith('@') && user.permissions?.includes(perm.substring(1))) return true;else if (perm.startsWith('!') && !user.permissions?.includes(perm.substring(1))) return true;else if (!perm.startsWith('@') && !perm.startsWith('!') && user.permissions?.includes(perm)) return true;
      return false;
    });
    if (!hasPerm) {
      el?.parentNode?.removeChild(el);
    }
  };
  let user = store.getters['auth/user'];
  if (user) {
    checkPerm(el, binding, vnode, user);
  } else {
    const unwatch = store.watch((state, getters) => getters['auth/user'], user => {
      if (user) {
        checkPerm(el, binding, vnode, user);
        unwatch();
      }
    }, {
      immediate: true
    });
  }
});