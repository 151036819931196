import { computed, nextTick, ref, toRef, watch } from "vue";
import { debounce, filter, find, findIndex, get, isEqual, isNil } from "lodash";
import { useField } from "vee-validate";
export default {
  name: "BaseSelect",
  props: {
    modelValue: {},
    value: {},
    name: {
      type: String
    },
    items: {
      type: Array
    },
    itemText: {
      type: String
    },
    itemValue: {
      type: String
    },
    label: String,
    clearable: {
      type: Boolean
    },
    errorMessage: {
      type: String
    },
    warningMessages: {
      type: String
    },
    successMessages: {
      type: String
    },
    noDetails: Boolean,
    immediate: {
      type: Boolean
    },
    hint: String,
    refKey: {
      type: String,
      default: ""
    },
    returnObject: {
      type: Boolean
    },
    required: {
      type: Boolean
    },
    itemHeight: {
      type: [Number, String]
    },
    active: {
      type: Boolean
    },
    disabled: {},
    readonly: {
      type: Boolean
    },
    filterColumns: {
      type: Array
    },
    selection: {
      type: Function
    },
    customPlaceholder: {
      type: String
    },
    rules: {},
    loading: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    autocompleteMinLength: {
      type: Number,
      default: 0
    },
    autocompleteDelay: {
      type: [Number, String],
      default: 500
    }
  },
  emits: ["update:modelValue", "update:autoComplete", "input", "blur", "focus", "change", "keydown", "keyup", "keypress"],
  setup(props, {
    emit
  }) {
    const text = ref(null);
    const selectedIndex = ref(-1);
    const showItems = ref(false);
    const selectedValue = ref(props.modelValue ?? props.value ?? null);
    const focused = ref(false);
    const baseSelectRef = ref(null);
    const selectInputRef = ref(null);
    const computedHeight = computed(() => {
      if (!selectInputRef.value || !baseSelectRef.value) return "initial";
      let sEl = baseSelectRef.value.querySelector(".selection");
      let sStyle = getComputedStyle(sEl);
      let iStyle = getComputedStyle(selectInputRef.value);
      let sHeight = parseFloat(sStyle.height) || 0;
      let iHeight = parseFloat(iStyle.height) || 0;
      if (sHeight > iHeight) return sHeight + "px !important";else return "initial";
    });
    const selectedItem = computed(() => {
      return find(props.items, {
        [props.itemValue]: selectedValue.value
      });
    });
    const filteredItems = computed(() => {
      const searchTextLower = text.value?.trim()?.toLowerCase();
      if (!searchTextLower) return props.items;
      return filter(props.items, result => {
        if (typeof result === "object") {
          const label = get(result, props.itemText);
          if (!label) return false;
          return label?.toLowerCase()?.includes(searchTextLower);
        }
      });
    });
    const {
      value: inputValue,
      errorMessage: error,
      handleBlur: veeHandleBlur,
      handleChange: veeHandleChange,
      resetField,
      meta
    } = useField(toRef(props, "refKey"), props.rules ?? null, {
      initialValue: selectedValue.value,
      validateOnMount: props.immediate,
      validateOnValueUpdate: props.immediate || props.autocomplete
    });
    const itemSelect = async (item, updateModelValue = true) => {
      if (typeof item === "object") {
        text.value = get(item, props.itemText);
      } else text.value = item;
      showItems.value = false;
      selectedValue.value = get(item, props.itemValue);
      selectedIndex.value = findIndex(props.items, {
        [props.itemValue]: selectedValue.value
      });
      inputValue.value = props.autocomplete && text.value ? text.value : selectedValue.value;
      if (updateModelValue) {
        if (props.returnObject) {
          emit("update:modelValue", item);
          emit("change", item);
        } else {
          emit("update:modelValue", selectedValue.value);
          emit("change", selectedValue.value);
        }
      }
    };

    // handle initial value if  modelValue or value is provided
    watch(() => [props.modelValue, props.value, props.items], (v, o) => {
      let x = v?.[0] ?? v?.[1] ?? null; // new value
      let y = o?.[0] ?? o?.[1] ?? null; // old value
      // avoid infinite loop
      if (isEqual(x, y)) return;
      if (isNil(x)) {
        selectedIndex.value = -1;
        return;
      }
      let item = null;
      /* prettier-ignore */
      if (props.returnObject) item = find(props.items, e => e[props.itemValue] === x[props.itemValue]);else item = find(props.items, e => e[props.itemValue] === x);
      if (item) itemSelect(item, false);
    }, {
      immediate: true,
      deep: true
    });
    resetField();
    const debouncedInput = debounce(v => {
      text.value = v?.trim();
    }, 500);
    const debouncedAutocomplete = debounce(v => {
      text.value = v?.trim();
      inputValue.value = text.value;
      selectedIndex.value = text.value;
      emit("update:autoComplete", v);
    }, (props.autocompleteDelay ?? 0) * 1, {
      trailing: true
    });
    const handleInput = ev => {
      emit("input", ev?.target?.value);
      if (props.autocomplete && ev.target.value.length >= props.autocompleteMinLength) debouncedAutocomplete(ev.target.value);else debouncedInput(ev.target.value);
    };
    const handleArrowDown = () => {
      if (selectedIndex.value < filteredItems.value.length - 1) {
        selectedIndex.value++;
      }
    };
    const handleArrowUp = () => {
      if (selectedIndex.value > 0) {
        selectedIndex.value--;
      }
    };
    const handleEnter = event => {
      if (selectedIndex.value !== -1) itemSelect(filteredItems.value[selectedIndex.value]);
      event.target.blur();
    };
    const scrollToItem = () => {
      const scrollContainer = document.querySelector(".items-container");
      const selectedElement = scrollContainer?.querySelector(".selected");
      if (selectedElement) {
        scrollContainer.scrollTop = selectedElement.offsetTop - scrollContainer.offsetTop;
      }
    };
    const handleFocus = async () => {
      if (!props.autocomplete) text.value = "";
      showItems.value = true;
      focused.value = true;
      await nextTick();
      scrollToItem();
      emit("focus", {
        target: selectInputRef.value,
        value: selectedValue.value
      });
    };
    const handleBlur = event => {
      // if input has text, find the item with similar text as text value and select it
      // this is to handle auto complete when user types in the input
      if (!props.autocomplete) {
        let item = find(props.items, i => {
          if (typeof i === "object") {
            const label = get(i, props.itemText);
            if (!label) return false;
            return label?.toLowerCase() === text.value?.toLowerCase();
          }
        });
        if (item) itemSelect(item);
      }
      veeHandleBlur(event);
      focused.value = false;
      const cl = event?.relatedTarget?.classList;
      if (!event?.relatedTarget || !cl?.contains("items-container")) {
        showItems.value = false;
        if (!isNil(selectedValue.value)) itemSelect(find(props.items, {
          [props.itemValue]: selectedValue.value
        }));
      }
    };
    const clear = async () => {
      text.value = "";
      await nextTick();
      itemSelect(null);
    };
    const focus = () => {
      selectInputRef.value?.focus();
    };
    const select = () => {
      selectInputRef.value?.select();
    };
    return {
      get,
      isNil,
      text,
      selectedIndex,
      filteredItems,
      itemSelect,
      handleInput,
      handleArrowDown,
      handleArrowUp,
      handleEnter,
      handleFocus,
      showItems,
      error,
      handleBlur,
      meta,
      veeHandleChange,
      focused,
      selectedItem,
      selectedValue,
      selectInputRef,
      baseSelectRef,
      computedHeight,
      clear,
      focus,
      select
    };
  }
};