import { ref, nextTick, inject } from "vue";
import { string } from "yup";
import find from "lodash/find";
export default {
  name: "UserLookup",
  props: {
    selected: {},
    disabled: {
      type: Boolean
    },
    internalOnly: {
      type: Boolean
    },
    internalPriority: {
      type: Boolean
    },
    externalOnly: {
      type: Boolean
    },
    normalize: {
      type: Boolean,
      default: true
    },
    companyID: {
      type: Number
    },
    includeAddresses: {
      type: Boolean
    },
    noCheckOption: {
      type: Boolean
    },
    label: {
      type: String,
      default: "Search for user"
    },
    customPlaceholder: {
      type: String,
      default: "Enter user ID Number"
    },
    required: {
      type: Boolean
    }
  },
  emits: ["searching", "update:selected", "update:searchText", "update:checkOption", "update:nextStep"],
  setup(props, {
    emit
  }) {
    const API = inject("axios");
    const userLookup = ref({
      key: 0,
      selected: null,
      completed: false,
      loading: false,
      rules: string().required("ID number is required").matches(/(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/, "Invalid ID number")
    });

    // const userRef = ref(null);
    const userLookupForm = ref(null);
    const userSelect = ref(null);
    const selectLookupItem = async uuid => {
      if (uuid === false) {
        userLookup.value.completed = false;
        userLookup.value.nextStep = false;
        await nextTick();
        userSelect.value?.focus();
        emit("update:selected", false);
        emit("update:nextStep", false);
        return;
      }
      if (!uuid || uuid === true) {
        await nextTick();
        userLookup.value.selected = null;
        userLookup.value.item = null;
        userLookup.value.nextStep = true;
        emit("update:selected", true);
        emit("update:nextStep", true);
        return;
      }
      let item = find(userLookup.value.items, {
        uuid
      });
      userLookup.value.item = item;
      userLookup.value.nextStep = true;
      emit("update:selected", item);
      emit("update:nextStep", true);
    };
    const searchForUser = async searchText => {
      userLookup.value.searchText = searchText;
      await nextTick();
      userLookup.value.completed = false;
      const isValid = await userLookupForm.value?.validate();
      if (!isValid.valid) return;
      if (userLookup.value.completed) return;
      userLookup.value.loading = true;
      API.post("/global/search/lookup/user", {
        IDNumber: searchText,
        internalOnly: props.internalOnly,
        internalPriority: props.internalPriority,
        externalOnly: props.externalOnly,
        normalize: props.normalize,
        includeAddresses: props.includeAddresses,
        companyID: props.companyID
      }, {
        timeout: 1000 * 60 * 5
      }).then(({
        data
      }) => {
        userLookup.value.items = data ?? [];
        userLookup.value.completed = true;
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        userLookup.value.loading = false;
      });
    };
    return {
      userLookup,
      userLookupForm,
      userSelect,
      selectLookupItem,
      searchForUser
    };
  }
};