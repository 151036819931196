import { h } from "vue";
export default {
  functional: true,
  name: "BaseTab",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  render() {
    return h("div", null, this.$slots.default());
  }
};