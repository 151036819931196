import OCLoadingComponent from "./OCLoadingComponent.vue";
import { defineAsyncComponent } from "vue";
const componentOptions = {
  loadingComponent: OCLoadingComponent,
  delay: 200,
  timeout: 3000
};
export const componentMap = {
  OwnershipSignatory: {
    component: defineAsyncComponent({
      loader: () => import("./OwnershipSignatoryComponent.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  },
  OwnershipOrdinaryResolutionSignatory: {
    component: defineAsyncComponent({
      loader: () => import("./OwnershipOrdinaryResolutionSignatoryComponent.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  },
  CIPCDirectorChange: {
    component: defineAsyncComponent({
      loader: () => import("./CIPCDirectorChangeComponent.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  },
  CompanySecretaryAppointment: {
    component: defineAsyncComponent({
      loader: () => import("./CompanySecretaryAppointmentComponent.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  },
  YearEndOrdinaryResolution: {
    component: defineAsyncComponent({
      loader: () => import("./YearEndOrdinaryResolutionComponent.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  },
  COROrdinaryResolution: {
    component: defineAsyncComponent({
      loader: () => import("./ChangeOfAddressOrdinaryResolutionComponent.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  },
  CompanyPublicOfficerAppointment: {
    component: defineAsyncComponent({
      loader: () => import("./CompanyPublicOfficerAppointment.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  },
  UltimateBeneficiaryReport: {
    component: defineAsyncComponent({
      loader: () => import("./BeneficialOwnershipFilingComponent.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  },
  DirectorSignBeneficialOwnership: {
    component: defineAsyncComponent({
      loader: () => import("./DirectorUBRComponent.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  },
  DirectorAppointment: {
    component: defineAsyncComponent({
      loader: () => import("./DirectorAppointmentComponent.vue"),
      ...componentOptions
    }),
    actionBtnText: "Sign"
  }
};