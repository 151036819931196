import { createRouter, createWebHistory } from 'vue-router';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import DefaultRouterView from '@/pages/main/views/DefaultRouterView.vue';
import SecureLS from 'secure-ls';
import Cookies from 'js-cookie';
const SecureLocalStorage = new SecureLS({
  encodingType: 'aes'
});
let lfStore = localforage.createInstance({
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE, memoryDriver._driver],
  name: 'post_imcp'
});

// create store on winow object
window.imcp = lfStore;
const routes = [
// landing page view
{
  path: '/:id?',
  component: () => import( /* webpackChunkName: "admin-companies" */'../views/Admin/PortfolioView.vue')
},
// dashboard view
{
  path: '/dashboard/:id',
  name: "Dashboard",
  component: () => import( /* webpackChunkName: "Dashboard" */'../views/DashboardView.vue')
}, {
  path: '/onboarding/:id',
  name: "Onboarding",
  component: () => import( /* webpackChunkName: "OnBoarding" */'../views/OnBoardingView.vue')
},
// document hub view
{
  path: '/documenthub/:id',
  name: "Main Document Hub",
  component: () => import( /* webpackChunkName: "documenthub" */'../views/DocumentHub/MainView.vue'),
  children: [{
    path: '',
    name: "Document Hub",
    component: () => import( /* webpackChunkName: "documenthub" */'../views/DocumentHub/DocumentHubView.vue')
  }, {
    path: 'checklist/:documentID',
    name: "Checklist",
    component: () => import( /* webpackChunkName: "documenthub" */'../views/DocumentHub/ChecklistView.vue')
  }]
},
// company profile view
{
  path: '/companyprofile/:id',
  name: "CompanyProfile",
  component: () => import( /* webpackChunkName: "company-profile" */'../views/CompanyProfile/CompanyProfileView.vue')
},
// SARS view
{
  path: '/sars/:id',
  name: "SARS",
  component: () => import( /* webpackChunkName: "sars" */'../views/SARSView.vue')
}, {
  path: '/connections/:id',
  name: "Connections",
  component: () => import( /* webpackChunkName: "connections" */'../views/ConnectionsView.vue')
},
// CIPC view
{
  path: '/cipc/:id',
  name: "CIPC",
  component: () => import( /* webpackChunkName: "cipc" */'../views/CIPCView.vue')
},
// ee information view
{
  path: '/ee-info/:id',
  name: "EEInfo",
  component: () => import( /* webpackChunkName: "ee-info" */'../views/EEInformationView.vue')
},
// skills training information view
{
  path: '/st-info/:id',
  name: "STInfo",
  component: () => import( /* webpackChunkName: "st-info" */'../views/STInformationView.vue')
}, {
  path: '/meetings/:id',
  name: "Meetings",
  component: DefaultRouterView,
  children: [{
    path: '',
    name: "Meeting",
    component: () => import( /* webpackChunkName: "meetings" */'../views/Meetings/MeetingsView.vue')
  }, {
    path: 'agenda/:meetingID',
    name: "Agenda",
    component: () => import( /* webpackChunkName: "meetings" */'../views/Meetings/AgendaView.vue')
  }, {
    path: 'minutes/:meetingID',
    name: "Minutes",
    component: () => import( /* webpackChunkName: "meetings" */'../views/Meetings/MinutesView.vue')
  }, {
    path: 'attendance/:meetingID',
    name: "Attendance",
    component: () => import( /* webpackChunkName: "meetings" */'../views/Meetings/AttendanceView.vue')
  }]
},
// ownership view
{
  path: '/ownership/:id',
  component: DefaultRouterView,
  children: [{
    path: '',
    name: "Ownership2",
    component: () => import( /* webpackChunkName: "Ownership" */'../views/Ownership/OwnershipView.vue')
  }]
},
// employees view
{
  path: '/employees/:id',
  name: "Employees",
  component: () => import( /* webpackChunkName: "Employees" */'../views/Employees/EmployeesView.vue'),
  children: [{
    path: 'meetings',
    name: "Employees Meetings",
    component: () => import( /* webpackChunkName: "Employees" */'../views/Employees/EmployeeMeetingsView.vue')
  }]
},
// ee view
{
  path: '/ee/:id',
  name: "Employment Equity",
  component: () => import( /* webpackChunkName: "ee-view" */'../views/EmploymentEquity/EEView.vue'),
  children: []
}, {
  path: '/ee/:id/eea12-report/:reportID?',
  name: "EEA12AnalysisReportView",
  component: () => import( /* webpackChunkName: "eea12-report" */'../views/EmploymentEquity/EEA12AnalysisReportView.vue')
}, {
  path: '/ee/:id/eea13-plan/:reportID?',
  name: "EEA13PlanView",
  component: () => import( /* webpackChunkName: "eea13-plan" */
  '../views/EmploymentEquity/EEA13PlanView.vue')
}, {
  path: '/ee/:id/eea2-submission/:submissionID?',
  name: "EEA2SubmissionView",
  component: () => import( /* webpackChunkName: "eea2-submission" */
  '../views/EmploymentEquity/EEA2SubmissionView.vue')
}, {
  path: '/ee/:id/eea4-submission/:submissionID?',
  name: "EEA4SubmissionView",
  component: () => import( /* webpackChunkName: "eea4-submission" */
  '../views/EmploymentEquity/EEA4SubmissionView.vue')
},
// skills training view
{
  path: '/st/:id',
  name: "ST",
  component: () => import( /* webpackChunkName: "st-view" */'../views/SkillsTraining/STView.vue')
},
// add training 
{
  path: '/addtraining/:id',
  name: "AddTraining",
  component: () => import( /* webpackChunkName: "add-training" */'../views/SkillsTraining/AddTraining.vue')
},
// ee meetings
{
  path: '/ee/:id/meetings',
  name: "EE Meetings",
  component: () => import( /* webpackChunkName: "ee-meetings" */
  '../views/EmploymentEquity/EEMeetings.vue')
},
// ee meetings
{
  path: '/stmeetings/:id',
  name: "STMeetings",
  component: () => import( /* webpackChunkName: "st-meetings" */
  '../views/SkillsTraining/STMeetings.vue')
},
// minutes
{
  path: '/minutesA12/:id',
  name: "MintesA12",
  component: () => import( /* webpackChunkName: "minutes-a12" */
  '../views/EmploymentEquity/MinutesA12.vue')
},
// minutes
{
  path: '/user-profile/:id',
  name: "UserProfile",
  component: () => import( /* webpackChunkName: "user-profile" */
  '../views/UserProfileView.vue')
},
// admin routes
{
  path: '/admin/:id?',
  name: "Admin",
  component: DefaultRouterView,
  children: [{
    path: 'employees',
    name: "Admin - Employees",
    component: () => import( /* webpackChunkName: "admin-employees" */'../views/Admin/AdminEmployeesView.vue')
  }, {
    path: 'companies',
    name: "My Portfolio",
    component: () => import( /* webpackChunkName: "admin-companies" */'../views/Admin/PortfolioView.vue')
  }, {
    path: 'users',
    name: "Admin - Users",
    component: () => import( /* webpackChunkName: "admin-users" */'../views/Admin/AdminUsersView.vue')
  }]
},
// suppliers view
{
  path: '/suppliers/:id',
  name: "Suppliers",
  component: () => import( /* webpackChunkName: "suppliers-view" */'../views/SuppliersView.vue'),
  children: []
}, {
  path: '/2fa-setup',
  name: "2FA Setup",
  component: () => import( /* webpackChunkName: "2fa-setup" */'../views/TwoFactorSetupView.vue')
},
// BEE view
{
  path: '/bee/:id',
  name: "BEE",
  component: () => import( /* webpackChunkName: "BEE-view" */'../views/BEEView.vue'),
  children: []
}, {
  path: '/bee-info/:id',
  name: "BEE Overview",
  component: () => import( /* webpackChunkName: "BEEOverview-view" */'../views/BEEOverview.vue'),
  children: []
},
// billing view
{
  path: '/billing/:id',
  name: "Billing",
  component: () => import( /* webpackChunkName: "billing-view" */'../views/BillingView.vue'),
  children: []
},
// billing views
{
  path: '/billing/subscribe',
  name: "Subscribe",
  meta: {
    noNav: true
  },
  component: () => import( /* webpackChunkName: "billing-view" */'../views/Billing/SubscribeView.vue')
}, {
  path: '/billing/Checkout',
  name: "Checkout",
  meta: {
    noNav: true
  },
  component: () => import( /* webpackChunkName: "billing-view" */'../views/Billing/CheckoutView.vue')
},
// test view
{
  path: '/test',
  component: () => import( /* webpackChunkName: "Test" */'../views/TestView.vue')
}];
const router = createRouter({
  history: createWebHistory('/app/'),
  routes
});
const do2FACheck = async (to, from, next, user) => {
  if (to.path == '/2fa-setup' && !user?.preferences?.twoFactorEnabled) return next();else if (to.path != '/billing/subscribe' && user && !user?.plan && (user?.roles ?? [])?.includes('BI')) return next({
    path: `/billing/subscribe`
  });else if (to.path == '/billing/subscribe' && user?.plan) return next({
    path: '/'
  });else if (to.path == '/2fa-setup' && user?.preferences?.twoFactorEnabled) return next({
    path: '/'
  });else if (!user?.preferences?.twoFactorEnabled) return next({
    path: `/2fa-setup?to=${to.path}`
  });
  return next();
};

/* eslint-disable no-unused-vars */
export default {
  install(app) {
    router.install(app);
    const store = app.config.globalProperties.$store;
    const API = app.config.globalProperties.$api;
    const toast = app.config.globalProperties.$toast;
    router.beforeEach(async (to, from, next) => {
      let returnUrl = to.query?.return;
      const token = store.getters['auth/token'];
      const user = store.getters['auth/user'];
      let accesstToken = to.query?.access;
      if (to.path == '/logout') {
        Cookies.remove('access');
        window.localStorage.removeItem('vx');
        SecureLocalStorage.remove('vx');
        window.stop;
        window.location.href = process.env.VUE_APP_LOGIN_URL + '/logout';
      }
      if (returnUrl) {
        window.stop();
        location.href = to.query.return;
      }
      let cookieToken = Cookies.get('access');
      if (cookieToken) accesstToken = cookieToken;

      /* eslint-disable no-unreachable */
      return await API.get(`/global/checktoken?token=${accesstToken ?? token}`).then(async res => {
        if (res.status == 200) {
          store.dispatch('auth/setToken', res.data.token);
          app.config.globalProperties.$api.defaults.headers.common['Authorization'] = store.getters['auth/token'];
          API.defaults.headers.common['Authorization'] = store.getters['auth/token'];
          if (!to.query?.access) {
            if (to.path == '/connections' && to.query.code && to.query.state) return connectToXero(API, to, next);
            return do2FACheck(to, from, next, user);
          }
          return res.data;
        }
      }).then(async () => {
        if (to.query?.access || !user) return await API.get('users/getuser').then(({
          data
        }) => {
          store.dispatch('auth/setUser', data);
          toast({
            text: "Welcome! You have been successfully logged in",
            type: "success"
          });
          return do2FACheck(to, from, next, data);
        }).catch(err => {
          window.stop();
          window.location.href = process.env.VUE_APP_LOGIN_URL + '?error=Invalid Login Attempt';
        });
      }).catch(err => {
        window.stop();
        window.location.href = process.env.VUE_APP_LOGIN_URL + '?error=Session expired';
      });
    });
    router.afterEach(() => {
      window.productFruits?.pageChanged?.();
      /* Life Ring Button */
      let lrButton = window.productFruits?.api?.inAppCenter;
      if (lrButton && !lrButton.isOpened()) {
        lrButton.show();
        lrButton.close();
      }
    });
  }
};
const connectToXero = async (API, to, next) => {
  return await API.post(`/connections/xero/connect`, {
    code: to.query.code,
    state: to.query.state,
    code_verifier: localStorage.getItem('code_verifier'),
    redirect_uri: process.env.VUE_APP_APP_URL + "/connections"
  }).then(({
    data
  }) => {
    let x = {
      companyID: data?.companyID,
      data: data.tenants,
      auth_user: data.auth_user,
      tokens: data.tokens,
      expires_at: new Date(new Date().getTime() + 30 * 60 * 1000).getTime()
    };
    return lfStore.setItem('xero', x).then(() => {
      return next({
        path: `/connections/${data?.companyID}?xero=1`
      });
    });
  }).catch(err => {
    console.log(err);
    return next();
  });
};