import filter from "lodash/filter";
import some from "lodash/some";
import has from "lodash/has";
import { h as hh } from "vue";
export default {
  components: {
    Vnodes: {
      functional: true,
      props: ["vnodes", "show"],
      render: h => {
        if (h.show == undefined) return h.$props.vnodes;else return hh("div", {
          class: h.$props?.class,
          style: {
            display: h.$props?.show ? "" : "none"
          }
        }, h.$props.vnodes);
      }
    }
  },
  props: {
    modelValue: {
      default: 0
    },
    activeClass: {
      type: String,
      default: "bg-white text-secondary-500"
    },
    secondaryTab: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },
    refer: {
      type: Boolean,
      default: false
    },
    overflowContent: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  computed: {
    someHasValue() {
      return some(this.tabItems, e => Object.prototype.hasOwnProperty.call(e.props ?? {}, "value"));
    },
    inActiveClass() {
      return this.secondaryTab ? "active-tab bg-white border-b-2 border-secondary-500 text-secondary-500" : "active-tab " + this.activeClass;
    }
  },
  data() {
    return {
      tabs: [],
      tabHeaders: [],
      tabItems: [],
      key: 0,
      observer: null
    };
  },
  mounted() {
    this.createTabs();
    this.initObserver();
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
  methods: {
    has,
    createTabs() {
      let x = this.$slots?.default() ?? [];
      this.tabs = filter(x, e => {
        return e?.type?.name == "BaseTab";
      });
      this.tabItems = filter(x, e => {
        return e?.type?.name == "BaseTabItem";
      });
      this.key++;
    },
    async initObserver() {
      let config = {
        subtree: false,
        childList: true
        // it's better if you can detect children change by id
        // this can reduce number of updates
        // attributes: true,
        // attributeList: [ 'id' ]
      };
      const self = this;
      const callback = () => {
        self.$nextTick(() => {
          self.createTabs();
        });
      };
      const observer = new MutationObserver(callback);
      observer.observe(this.$refs.tabs, config);
      this.observer = observer;
    }
  },
  watch: {
    modelValue() {
      this.createTabs();
    }
    // watch ref parent
    // $refs: {
    // 	handler() {
    // 		console.log("ref changed");
    // 	},
    // 	deep: true,
    // 	immediate: true,
    // },
  }
};