import { inject } from "vue";
import { subscriptionGuardOpenIK, subscriptionGuardMessageIK, subscriptionGuardStatusIK, isSubscriberIK } from ".";
export default {
  name: "SubscriptionGuard",
  setup() {
    const open = inject(subscriptionGuardOpenIK, false);
    const message = inject(subscriptionGuardMessageIK, null);
    const status = inject(subscriptionGuardStatusIK, null);
    const isSubscriber = inject(isSubscriberIK, false);
    return {
      open,
      message,
      status,
      isSubscriber
    };
  }
};