import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RouterView = _resolveComponent("RouterView");
  return _openBlock(), _createBlock(_component_RouterView, null, {
    default: _withCtx(({
      Component
    }) => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: _ctx.$route.params?.id
    }))]),
    _: 1
  });
}