import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "flex flex-col w-full h-full bg-[rgba(56,56,61,1)]"
};
const _hoisted_2 = {
  class: "p-2.5 text-lg font-semibold text-white"
};
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_base_modal = _resolveComponent("base-modal");
  return $setup.pdfViewerOpen ? (_openBlock(), _createBlock(_component_base_modal, {
    modelValue: $setup.pdfViewerOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.pdfViewerOpen = $event),
    closable: "",
    width: $setup.width,
    "min-height": $setup.height,
    onCloseModal: _cache[1] || (_cache[1] = $event => $setup.closePDFViewer()),
    key: $setup.iframeURL
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($setup.pdfName), 1), _createElementVNode("iframe", {
      src: $setup.iframeURL,
      frameborder: "0",
      width: "100%",
      height: "100%",
      referrerpolicy: "no-referrer"
    }, null, 8, _hoisted_3)])]),
    _: 1
  }, 8, ["modelValue", "width", "min-height"])) : _createCommentVNode("", true);
}