import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-535f051e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: ""
};
const _hoisted_2 = ["href", "onClick"];
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_link, _mergeProps({
    to: $props.disabled ? '#' : $props.to
  }, _ctx.$attrs, {
    custom: ""
  }), {
    default: _withCtx(({
      href,
      navigate,
      isActive,
      isExactActive
    }) => [_createElementVNode("li", {
      class: _normalizeClass({
        'router-link-active': isActive && !$props.disabled,
        'router-link-exact-active': isExactActive && !$props.disabled,
        'router-link-disabled': $props.disabled
      })
    }, [!$props.disabled ? (_openBlock(), _createElementBlock("a", {
      key: 0,
      href: href,
      onClick: navigate,
      class: "h-[2.15rem]"
    }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 8, _hoisted_2)) : (_openBlock(), _createElementBlock("span", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]))], 2)]),
    _: 3
  }, 16, ["to"])]);
}