import * as Ably from 'ably';
import store from '@/pages/main/store';
export default {
  install: app => {
    const ABLY_API_KEY = process.env.VUE_APP_ABLY_API_KEY;
    const toast = app.config.globalProperties.$toast;
    const router = app.config.globalProperties.$router;
    const user = store.getters['auth/user'];
    const client = new Ably.Realtime({
      key: ABLY_API_KEY
    });
    let createUserChannelSubscription = accountID => {
      const userChannel = client.channels.get(`users:${accountID}`);
      userChannel.subscribe('user-update', async msg => {
        const notification = msg.data?.notification;
        try {
          await store.dispatch('auth/fetchUser');
          if (notification) toast(notification);
          // eslint-disable-next-line no-empty
        } catch {}
      });
      userChannel.subscribe('logout', async msg => {
        const reason = msg.data?.reason;
        if (!reason) router.push('/logout');else {
          toast({
            text: `You will be logged out shortly. Reason: ${reason}`,
            type: 'error',
            timeout: 10000
          });
          setTimeout(() => router.push('/logout'), 10000);
        }
      });
      userChannel.subscribe('user-messages', msg => {
        store.dispatch('messages/addMessage', msg.data);
      });
    };
    if (!user) {
      const unwatch = store.watch((state, getters) => getters['auth/user'], v => {
        if (v) {
          createUserChannelSubscription(v.accountID);
          unwatch();
        }
      }, {
        immediate: true,
        deep: true
      });
    } else createUserChannelSubscription(user.accountID);
    const messagesChannel = client.channels.get(`messages`);
    messagesChannel.subscribe('message', msg => {
      let msgData = msg.data;
      if (msgData.type == 'message') store.dispatch('messages/addMessage', msgData);else if (msgData.type == 'notification') toast(msgData.notification);else if (msgData.type == 'alert') store.dispatch('messages/createAlert', msgData);
    });
    client.connection.on('connected', () => {});
    window.addEventListener('beforeunload', () => {
      console.log('closing ably connection');
      client.close();
    });
  }
};