import { ref } from "vue";
import UserComponent from "./UserComponent.vue";
import UserProfile from "./UserProfile.vue";

//IK = Injection Key
export const userIDIK = Symbol('userIDIK');
export const profileOpenIK = Symbol('profileOpenIK');
export const profileOptionsIK = Symbol('profileOptionsIK');
export const accountIDIK = Symbol('accountIDIK');
export const userProfilePlugin = {
  install: app => {
    const userID = ref(0);
    const profileOpen = ref(false);
    const profileOptions = ref({});
    const accountID = ref(null);
    app.provide(userIDIK, userID);
    app.provide(profileOpenIK, profileOpen);
    app.provide(profileOptionsIK, profileOptions);
    app.provide(accountIDIK, accountID);
    app.component('user-component', UserComponent);
    app.component('user-profile', UserProfile);
    const handler = async (id, options) => {
      userID.value = id;
      profileOptions.value = options;
      breakAcount: if (options?.accountID) {
        const API = app.config.globalProperties.$api;
        const route = app.config.globalProperties.$route;
        const store = app.config.globalProperties.$store;
        options.companyID = (options?.companyID || route.params?.id || store.getters['companies/getCompany']?.companyID) * 1;
        if (!options?.companyID) break breakAcount;
        try {
          const response = await API.post(`/users/profile/${options.accountID}`, {
            companyID: options.companyID
          });
          profileOptions.value['profile'] = response.data;
          accountID.value = options.accountID;
        } catch (error) {
          console.error(error);
        }
      }
      profileOpen.value = true;
    };
    app.config.globalProperties.$userProfile = handler;
    app.provide('userProfile', handler);
  }
};