import { computed, inject, ref } from "vue";
import { debounce } from "lodash";
import { pdfViewerOpenIK, pdfURLIK, pdfNameIK } from "./PDFViewer";
export default {
  name: "PDFViewerComponent",
  props: {},
  setup() {
    const pdfViewerOpen = inject(pdfViewerOpenIK);
    const pdf = inject(pdfURLIK);
    const pdfName = inject(pdfNameIK);
    const iframeURL = computed(() => {
      let viewerURL = `${window.location.origin}/pdfjs-viewer/web/viewer.html?file=&fileUrl=${pdf.value}`;
      if (pdfName.value) viewerURL += `&filename=${pdfName.value}`;
      const url = new URL(viewerURL);
      return url.href;
    });
    const height = ref(window.innerHeight - window.innerHeight * 0.05);
    const width = ref(window.innerWidth - window.innerWidth * 0.05);
    const handleResize = debounce(() => {
      height.value = window.innerHeight - window.innerHeight * 0.05;
      width.value = window.innerWidth - window.innerWidth * 0.05;
    }, 400);
    window.addEventListener("resize", handleResize);
    const closePDFViewer = () => {
      pdfName.value = "";
      pdf.value = null;
    };
    return {
      pdfViewerOpen,
      pdfName,
      pdf,
      height,
      width,
      closePDFViewer,
      iframeURL
    };
  }
};