/* eslint-disable */
import find from "lodash/find";
import MazAvatar from "maz-ui/components/MazAvatar";
import { useRoute, useRouter } from "vue-router";
import { inject, ref, computed, nextTick, watch, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { cloneDeep, isNil } from "lodash";
import { differenceInCalendarDays } from 'date-fns';
export default {
  components: {
    MazAvatar
  },
  props: {
    open: {
      type: Boolean
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const user = computed(() => store.getters["auth/user"]);
    const isConsultant = computed(() => store.getters["auth/isConsultant"]);
    const profile = computed(() => store.getters["companies/getCompany"]);
    const API = inject("axios");
    const toast = inject("toast");
    const companies = ref([]);
    const selectedCompany = ref(null);
    const dropOpened = ref(false);
    const getCompanies = () => {
      if (!user.value?.accountID) return Promise.resolve([]);
      return API.get(`/usercompanies/${user.value?.accountID}`).then(async ({
        data
      }) => {
        companies.value = cloneDeep(data ?? []);
        return data;
      }).catch(err => {
        console.error(err);
        toast({
          text: "Could not get user companies. Please try again later.",
          type: "error"
        });
      });
    };
    const changeCompany = async companyID => {
      selectedCompany.value = companyID;
      router.push({
        ...route,
        params: {
          id: companyID
        }
      });
      await nextTick();
      let cp = await selectCompany(companyID);
      toast({
        text: `Changed active company to <strong>${cp?.companyName}</strong>`
      });
    };
    const isCompanyDisabled = computed(() => {
      if (profile.value && profile.value.createdAt && profile.value.companyStatusID == 2) {
        const daysSinceAdded = differenceInCalendarDays(new Date(), new Date(profile.value.createdAt));
        return daysSinceAdded > 14;
      }
    });
    const selectCompany = async companyID => {
      if (isNil(companyID)) {
        router.push("/user-profile/" + user.value?.accountID);
        return;
      }
      return getCompanyProfile(companyID).then(async cp => {
        localStorage.setItem("company", companyID);
        router.replace({
          ...route,
          params: {
            id: companyID
          }
        });
        if (!cp?.onboarding?.totalOnBoardingProgress || isCompanyDisabled.value || route.path?.includes("/onboarding")) router.push(`/onboarding/${companyID}`);else if (route.path == "/") router.replace(`/admin/${companyID}/companies`);
        return cp;
      });
    };
    const addNewCompany = () => {
      router.push(`/admin/${selectedCompany.value ?? 0}/companies?new=true`);
    };
    const getCompanyProfile = async companyID => {
      if (isNil(companyID)) {
        router.push("/user-profile/" + user.value?.accountID);
        return;
      }
      return API.get(`/companies/profile/${companyID}`).then(res => {
        let data = res.data;
        if (!data || !data?.companyID) return;
        store.dispatch("companies/setCompany", data);
        store.dispatch("companies/fetchpersonnel", companyID);
        store.dispatch("companies/fetchConnections", companyID);
        return data;
      }).catch(err => {
        console.error(err);
      });
    };
    watch(() => route.query, v => {
      if (v.refresh) {
        getCompanies().then(data => {
          if (!isNaN(v.refresh)) {
            let x = find(data, {
              companyID: v.refresh * 1
            });
            selectedCompany.value = x ? x?.companyID : data?.[0]?.companyID; // prettier-ignore
            selectCompany(selectedCompany.value);
          }
        });
      }
      let newQuery = {
        ...route.query
      };
      delete newQuery.refresh;
      router.replace({
        ...route,
        query: newQuery
      });
    }, {
      immediate: true,
      deep: true
    });
    onBeforeMount(() => {
      if (!user.value?.plan && (user.value?.roles, [])?.includes("BI")) {
        router.push("/billing/subscribe");
        return;
      }
      getCompanies().then(data => {
        let lsc = localStorage.getItem("company") * 1;
        let x = find(data, {
          companyID: lsc ? lsc : route.params.id
        });
        selectedCompany.value = x ? x?.companyID : data?.[0]?.companyID;
        if (selectedCompany.value) selectCompany(selectedCompany.value);
      });
    });
    return {
      find,
      user,
      profile,
      companies,
      selectedCompany,
      selectCompany,
      dropOpened,
      changeCompany,
      addNewCompany,
      isConsultant,
      isCompanyDisabled
    };
  }
};