import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-287b4bac"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Vnodes = _resolveComponent("Vnodes");
  return _openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, {
    ref: "tabs"
  }), [_createElementVNode("div", {
    class: _normalizeClass(["tabs justify-start gap-x-1", {
      'normal': !$props.secondaryTab,
      'secondary': $props.secondaryTab
    }])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabs, (t, i) => {
    return _openBlock(), _createElementBlock("a", {
      key: i,
      class: _normalizeClass(["tab tab-lifted font-semibold rounded-t-md text-sm", `${t.props?.class} ${$props.modelValue == i || t.props?.active ? $options.inActiveClass : ''} ${$props.full ? 'mx-1 grow' : ''} 
				${t.props?.disabled ? '!text-gray-300 cursor-not-allowed ' + ($props.secondaryTab ? 'bg-transparent' : '!bg-gray-100') : 'text-darkprimary-500'}`]),
      onClick: $event => !t.props?.disabled && _ctx.$emit('update:modelValue', i)
    }, [_createVNode(_component_Vnodes, {
      vnodes: t.children.default() ?? []
    }, null, 8, ["vnodes"])], 10, _hoisted_1);
  }), 128))], 2), $data.tabItems.length > 0 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["tabs__content p-2 bg-white rounded-b-md", {
      'overflow-y-auto': $props.overflowContent
    }])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabItems, (t2, j) => {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: j
    }, [($props.refer ? true : $props.modelValue == (t2.props?.value ?? $options.someHasValue ? t2.props?.value : j)) ? (_openBlock(), _createBlock(_component_Vnodes, {
      key: 0,
      show: $props.modelValue == (t2.props?.value ?? $options.someHasValue ? t2.props?.value : j),
      vnodes: t2?.children?.default() ?? []
    }, null, 8, ["show", "vnodes"])) : _createCommentVNode("", true)], 64);
  }), 128))], 2)) : _createCommentVNode("", true)], 16);
}