import { debounce } from "lodash";
import PDFViewerComponent from "./PDFViewerComponent";
import { ref } from "vue";

// IK = Injection Key
export const pdfViewerOpenIK = Symbol('pdfViewerOpen');
export const pdfURLIK = Symbol('pdfURL');
export const pdfNameIK = Symbol('pdfName');
export const PDFViewerPlugin = {
  install: app => {
    const pdfViewerOpen = ref(false);
    const pdfURL = ref(null);
    const pdfName = ref(null);
    app.provide(pdfViewerOpenIK, pdfViewerOpen);
    app.provide(pdfURLIK, pdfURL);
    app.provide(pdfNameIK, pdfName);
    app.component('PDFViewerComponent', PDFViewerComponent);
    const debouncedOpenInNewTab = debounce(options => {
      let viewerURL = `${window.location.origin}/pdfjs-viewer/web/viewer.html?file=&fileUrl=${options.pdf}`;
      if (options?.name) viewerURL += `&filename=${options.name}`;
      window.open(viewerURL, '_blank');
    }, 500, {
      leading: false,
      trailing: true
    });
    const handler = async options => {
      const toast = app.config.globalProperties.$toast;
      if (!options?.pdf) {
        if (!toast) return;
        toast({
          text: "PDF not found. Please make sure you have provided a valid PDF link.",
          type: "error"
        });
        return;
      }
      if (!options?.preview) {
        debouncedOpenInNewTab(options);
        return;
      }
      pdfViewerOpen.value = true;
      pdfURL.value = options?.pdf;
      pdfName.value = options?.name;
    };
    app.config.globalProperties.$pdfViewer = handler;
    app.provide('pdfViewer', handler);
  }
};