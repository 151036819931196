import { includes } from "lodash";
import { mapGetters } from "vuex";
import PDFViewerComponent from "@/pages/shared/plugins/PDFViwer/PDFViewerComponent.vue";
import ProductFruits from "@/pages/shared/plugins/ProductFruits";
export default {
  components: {
    PDFViewerComponent,
    ProductFruits
  },
  name: "App",
  data: () => ({
    loads: 0,
    open: true,
    height: document.documentElement.clientHeight,
    pfProjectCode: process.env.VUE_APP_PF_CODE,
    el: null
  }),
  computed: {
    ...mapGetters({
      profile: "companies/getCompany",
      user: "auth/user"
    })
  },
  async mounted() {
    var origOpen = XMLHttpRequest.prototype.open;
    let vm = this;
    XMLHttpRequest.prototype.open = function (m, u) {
      const exludes = ["refreshtoken", "r.logr-ingest.com"];
      if (!exludes.some(e => u.includes(e))) vm.loads++;
      let tm = setTimeout(() => vm.loads = 0, 60000);
      this.addEventListener("load", function () {
        setTimeout(() => {
          vm.loads > 0 && vm.loads--;
          clearTimeout(tm);
        }, 500);
      });
      origOpen.apply(this, arguments);
    };
    const fetch = window.fetch;
    window.fetch = (...args) => (async args => {
      let u = args[0];
      if (!u.includes("refreshtoken") || !includes("/api")) vm.loads++;
      let tm = setTimeout(() => vm.loads = 0, 60000);
      var result = await fetch(...args);
      setTimeout(() => {
        vm.loads > 0 && vm.loads--;
        clearTimeout(tm);
      }, 500);
      return result;
    })(args);
  },
  beforeUnmount() {
    // this.observer?.disconnect();
  }
};