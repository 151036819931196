import LogRocket from 'logrocket';
import store from '@/pages/main/store';
const requestSanitizer = request => {
  const allowedURLs = [process.env.VUE_APP_API_URL];
  const exludedURLs = [`${process.env.VUE_APP_API_URL}/global/refreshtoken`, `${process.env.VUE_APP_API_URL}/global/lookups`];
  if (exludedURLs.some(eu => request.url.startsWith(eu))) {
    return null;
  }
  if (allowedURLs.some(au => request.url.startsWith(au))) {
    return request;
  }
  return null;
};
const initLogRocket = user => {
  const logrocketID = process.env.VUE_APP_LOGROCKET_ID;
  if (!logrocketID) {
    console.warn('LogRocket could not be initialized');
    return;
  }
  LogRocket.init(logrocketID, {
    console: {
      enabled: true
    },
    network: {
      requestSanitizer
    }
  });
  let lrUUID = user.email;

  // if the window url is localhost, prepend 'local-' to the uuid
  if (window.location.hostname === 'localhost') {
    lrUUID = `local-${lrUUID}`;
  }
  console.log('LogRocket', lrUUID);
  LogRocket.identify(lrUUID, {
    name: user.fullName,
    email: user.email,
    accountID: user.accountID,
    plan: user.plan
  });
};
export const LogRocketPlugin = {
  install: () => {
    const user = store.getters['auth/user'];
    if (user) {
      initLogRocket(user);
      return;
    }
    const unwatch = store.watch((state, getters) => getters['auth/user'], v => {
      if (v) {
        initLogRocket(v);
        unwatch();
      }
    });
  }
};