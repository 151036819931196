import axios from 'axios';
export default {
  install: app => {
    const generatePDFDoc = (options, downalod = true) => {
      return new Promise((resolve, reject) => {
        axios({
          url: process.env.VUE_APP_PDF_API_URL + '/docs/generate',
          method: 'POST',
          data: options,
          responseType: "blob"
        }).then(response => {
          if (downalod) {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', options.filename);
            link.click();
          }
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    };
    app.config.globalProperties.$pdfGen = generatePDFDoc;
    app.provide('pdfGen', generatePDFDoc);
  }
};