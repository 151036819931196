import { inject, ref, shallowRef, computed } from "vue";
import { componentMap } from "./componentMap";
import { cloneDeep, filter, find } from "lodash";
export default {
  setup() {
    const API = inject("axios");
    const showOffcanvas = ref(false);
    const tabs = ref(0);
    const actions = ref([]);
    const messages = ref([]);
    const view = ref(false);
    const completedActions = computed(() => {
      return filter(actions.value, {
        completed: true
      });
    });
    const incompletedActions = computed(() => {
      return filter(actions.value, {
        completed: false
      });
    });
    const actionsValue = ref(1);
    const selectedActions = computed(() => {
      if (actionsValue.value == 2) return completedActions.value;else if (actionsValue.value == 1) return incompletedActions.value;else return actions.value;
    });
    const getActions = async () => {
      return API.get("/cta/actions").then(res => {
        console.log(res.data);
        actions.value = res.data;
        return res.data;
      }).catch(err => {
        console.log(err);
      });
    };
    const getMessages = async () => {
      return API.get("/cta/messages").then(res => {
        messages.value = res.data;
        return res.data;
      }).catch(err => {
        console.log(err);
      });
    };
    const toggleOffcanvas = () => {
      if (!showOffcanvas.value) {
        Promise.allSettled([getActions()]).then(res => {
          res;
          showOffcanvas.value = !showOffcanvas.value;
        });
      } else {
        showOffcanvas.value = !showOffcanvas.value;
      }
    };
    getMessages;
    const currentItem = shallowRef(null);
    const currentItemData = ref(null);
    const selectedAction = ref(null);
    const openAction = action => {
      view.value = true;
      currentItem.value = componentMap[action.type];
      selectedAction.value = cloneDeep(action);
      currentItemData.value = action.data;
    };
    const backClick = () => {
      if (view.value) {
        view.value = false;
        currentItem.value = null;
      }
    };
    const finish = () => {
      getActions().then(() => {
        let se = find(actions.value, {
          checklistItemID: selectedAction.value.checklistItemID
        });
        openAction(se);
      });
    };
    return {
      showOffcanvas,
      toggleOffcanvas,
      tabs,
      actions,
      completedActions,
      incompletedActions,
      actionsValue,
      selectedActions,
      messages,
      view,
      openAction,
      currentItem,
      currentItemData,
      backClick,
      finish,
      selectedAction
    };
  }
};