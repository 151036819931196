import { ref } from "vue";
import SubscriptionGuard from "./SubscriptionGuard.vue";
import store from "@/pages/main/store";

/* IK = Injection Key */
export const subscriptionGuardOpenIK = Symbol('subscriptionGuardOpenIK');
export const subscriptionGuardMessageIK = Symbol('subscriptionGuardMessageIK');
export const subscriptionGuardStatusIK = Symbol('subscriptionGuardStatusIK');
export const isSubscriberIK = Symbol('isSubscriberIK');
export const subscriptionGuardPlugin = {
  install: app => {
    app.component('subscription-guard', SubscriptionGuard);
    const subscriptionGuardOpen = ref(false);
    const subscriptionGuardMessage = ref(null);
    const subscriptionGuardStatus = ref(null);
    const isSubscriber = ref(false);
    app.provide(subscriptionGuardOpenIK, subscriptionGuardOpen);
    app.provide(subscriptionGuardMessageIK, subscriptionGuardMessage);
    app.provide(subscriptionGuardStatusIK, subscriptionGuardStatus);
    app.provide(isSubscriberIK, isSubscriber);
    const checkSubscription = async (user, options, callback) => {
      const allowedStatuses = ['active', 'trialing'];
      if (!user.plan) {
        isSubscriber.value = false;
        subscriptionGuardOpen.value = true;
        subscriptionGuardStatus.value = 'no_subscription';
        return;
      }
      if (!allowedStatuses.includes(user?.plan?.subscriptionStatus)) {
        isSubscriber.value = user?.plan?.subscriber ?? false;
        subscriptionGuardOpen.value = true;
        subscriptionGuardMessage.value = options?.message;
        subscriptionGuardStatus.value = user?.plan?.subscriptionStatus;
        return;
      }
      if (callback && typeof callback === 'function') callback();else callback;
    };
    const handler = async (callback, options) => {
      const user = store.getters['auth/user'];
      if (!user) {
        const unwatch = store.watch((state, getters) => getters['auth/user'], v => {
          if (v) {
            checkSubscription(user, options, callback);
            unwatch();
          }
        }, {
          immediate: true,
          deep: true
        });
      } else checkSubscription(user, options, callback);
    };

    // SUB_GUARD = Subscription Guard
    app.config.globalProperties.$SUB_GUARD = handler;
    app.provide('SUB_GUARD', handler);
  }
};
export const subscriptionGuardDirective = (el, binding, vnode) => {
  el, binding, vnode;
  const checkSubscription = async (el, binding, vnode, user) => {
    const allowedStatuses = ['active', 'trialing'];
    if (!allowedStatuses.includes(user?.plan?.subscriptionStatus)) {
      el.classList.remove(...el.classList);
      el.classList.add(...binding.value);
    }
  };
  const user = store.getters['auth/user'];
  if (!user) {
    const unwatch = store.watch((state, getters) => getters['auth/user'], v => {
      if (v) {
        checkSubscription(el, binding, vnode, user);
        unwatch();
      }
    }, {
      immediate: true,
      deep: true
    });
  } else checkSubscription(el, binding, vnode, user);
};