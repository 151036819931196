import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import API from '@/pages/shared/services/api';
import { userProfilePlugin } from '@/pages/shared/plugins/userProfile';
import { PDFViewerPlugin } from '@/pages/shared/plugins/PDFViwer/PDFViewer';
import generatePDFDoc from '@/pages/shared/plugins/generatePDFDoc';
import toastPlugin from '@/pages/shared/plugins/toast';
import { subscriptionGuardPlugin } from '@/pages/shared/plugins/subscriptionGuard';
import rolesDirective from './plugins/rolesDirective';
import permissionsDirective from './plugins/permissionsDirective';
import socketMessaging from '@/pages/shared/plugins/socketMessaging';
import { LogRocketPlugin } from '../shared/plugins/logrocket';
import { VTooltip, VClosePopper } from 'floating-vue';
import { mask } from "vue-the-mask";
import VueSignaturePad from 'vue-signature-pad';
import { Form } from "vee-validate";
// import debounce from 'lodash/debounce'

import 'floating-vue/dist/style.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'maz-ui/css/main.css';
import '@/pages/shared/styles/input.css';
import { loadFonts } from './plugins/webfontloader';
import map from 'lodash/map';
loadFonts();
const app = createApp(App).use(store);

// const refreshToken = debounce(function () {
//   // if session is expired, redirect to login page
//   store.dispatch('auth/refreshToken')
// }, 5000, { trailing: true })

// document.addEventListener("mousedown", refreshToken)
// document.addEventListener("scroll", refreshToken)
// document.addEventListener("click", refreshToken)
// document.addEventListener("keypress", refreshToken)
// document.addEventListener("mousemove", refreshToken)

app.config.globalProperties.$api = API;
app.provide('axios', app.config.globalProperties.$api);
app.config.globalProperties.$R2G = function getColor(value) {
  //value from 0 to 1
  var hue = (value * 120).toString(10);
  return ["hsl(", hue, ",100%,50%)"].join("");
};
app.config.globalProperties.$PROD = process.env.NODE_ENV === 'production';
app.provide('hasRole', store.getters['auth/hasRole']);
app.provide('isConsultant', store.getters['auth/isConsultant']);
app.provide('systemConnected', store.getters['companies/connectedTo']);

// global
app.config.globalProperties.jsonToExcel = function (json, fileName, headerMap) {
  const header = [map(headerMap.keys, (key, i) => headerMap.headers[i])];
  const filteredJson = json.map(row => {
    const filteredRow = {};
    headerMap.keys.forEach((key, i) => {
      filteredRow[`@${i}`] = row[key];
    });
    return filteredRow;
  });
  const XLSX = window.XLSX;
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(ws, header);
  XLSX.utils.sheet_add_json(ws, filteredJson, {
    skipHeader: true,
    origin: 'A2'
  });
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, fileName);
};
app.use(toastPlugin, {});
app.use(generatePDFDoc, {});
app.use(VueSignaturePad);
app.use(router, app);
app.use(PDFViewerPlugin, {});
app.use(socketMessaging, {});
/*eslint-disable vue/multi-word-component-names */
app.component("Form", Form);
app.directive('mask', mask);
app.directive('tooltip', VTooltip);
// app.use(VTooltip)
app.directive('close-popper', VClosePopper);
app.directive('roles', rolesDirective);
app.directive('perms', permissionsDirective);
app.use(userProfilePlugin, {});
store.dispatch("lookups/fetchDisabilities");
store.dispatch("lookups/fetchEthnicities");
store.dispatch("lookups/fetchUnions");
store.dispatch("lookups/fetchEducationalInstitutions");
store.dispatch("lookups/fetchCountries");
store.dispatch("lookups/fetchOFOCodes");
store.dispatch("lookups/fetchEmploymentStatuses");
store.dispatch("lookups/fetchNQFLevels");
store.dispatch("lookups/fetchEducationLevels");
store.dispatch("lookups/fetchMunicipalities");
store.dispatch("lookups/fetchShareClasses");
store.dispatch("lookups/fetchOccupationalLevels");
store.dispatch("lookups/fetchSETAClassifications");
store.dispatch("lookups/fetchIndustrySectors");
store.dispatch("lookups/fetchBargainingCouncils");
store.dispatch("lookups/fetchBusinessTypes");
store.dispatch("lookups/fetchSubscriptionPlans");
store.dispatch("lookups/fetchUserTitles");
store.dispatch("lookups/fetchUserRoles");
app.use(subscriptionGuardPlugin);
app.use(LogRocketPlugin);
app.mount('#app');